import React, { useState } from 'react'
import { Col, Row, Dropdown, Container, Offcanvas } from 'react-bootstrap'
import { NavLink } from 'react-router-dom';
import {useDispatch,useSelector} from 'react-redux' 
import LogoutModal from '../Modals/LogoutModal';

function Header({title}) {

    //modal
    const [showLogout, setShowLogout] = useState(false);

   const handleCloseLogout = () => setShowLogout(false);
   const handleShowLogout = () => setShowLogout(true);

    const dispatch = useDispatch(); 
     const switcher = useSelector(state => state.reducer.switcher)
    const [mobSearch,setMobSearch] = useState(false)

    const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const setSwitcher = (val) => {
    dispatch({
        type:"switching",
        value:{switcher:val}
    })
  }

  return (
    <>
    <div className='header mt-2 py-2 px-2 position-relative'>
        {mobSearch && 
    <div className='mob_searchbar_holder p-3 d-flex justify-content-start align-items-center gap-3'>
                            <input type='text' placeholder='Search' className='mob_searchbar_inp flex-grow-1' />
                            <i class="fa-solid fa-xmark" onClick={() => setMobSearch(false)}  />
                    </div>}
    <Row className='align-items-center'>
        <Col xl={6} lg={4} md={4} xs={8} className='d-flex gap-3'>
            <p className='header_title d-block d-xl-none' onClick={handleShow}><i class="fa-solid fa-bars" /></p>
            <p className='header_title'>{title}</p>
        </Col>
        <Col xl={6} lg={8} md={8} xs={4} className='d-flex justify-content-end align-items-center gap-3'>
            <div className='header_searchbar_holder flex-grow-1 d-none d-md-block'>
        <div className='header_searchbar rounded-5 d-flex justify-content-start align-items-center gap-2 px-3 py-2'>
                        <img src={require('../assets/images/searchglass.svg').default} className='img-fluid' />
                        <input type='text' className='header_searchbar_input flex-grow-1' placeholder='Search' />
                    </div>
                    </div>
                     {/* mobile searchbar */}
                     <img src={require('../assets/images/searchglass.svg').default} className='img-fluid mob_searchglass d-block d-md-none' onClick={() => setMobSearch(true)} />

                    
{/* end of mobile searchbar */}
                    <div className='header_switch_holder d-none d-sm-block'>
                    <div className='header_dragger p-2 rounded-5 position-relative d-flex justify-content-center align-items-center w-100' >
                        <img src={switcher ? require('../assets/images/bitcoin.svg').default : require('../assets/images/thunder.svg').default} className={`img-fluid header_switchThunder ${switcher ? 'header_thunderRight' : 'header_thunderLeft'}`} onClick={()=> setSwitcher(!switcher)} />
                        <p className={`header_switchHint ${switcher ? 'me-3' : 'ms-3'} `}>{switcher ? 'Switch to BTC' : 'Switch to LN'}</p>
                    </div>
                    </div>
                   
                    <div className='header_profileImg_holder'>
                    <Dropdown className='header_profile_drop' drop='down'>
      <Dropdown.Toggle variant="success" id="dropdown-basic">
                    <img src={require('../assets/images/profile.png')} className='img-fluid header_profileImg rounded-5' />
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item className='rounded-2' href='/profile'>Profile</Dropdown.Item>
        <Dropdown.Item className='rounded-2' href='/resetPassord'>Reset Password</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
                    </div>
        </Col>
    </Row>
    </div>

    {/* offcanva content */}
    <Offcanvas show={show} onHide={handleClose} backdrop="static" className='header_canvas'>
        <Offcanvas.Body>
            <div className='header_canva_header pb-2 d-flex justify-content-between align-items-center'>
                <img src={require('../assets/images/logo.svg').default} className='img-fluid' />
                <button className='cmn_modal_closer rounded-5' onClick={handleClose}><i class="fa-solid fa-xmark"/></button>
            </div>
            <ul className="pt-4">
            <li className="rounded-end-5 mb-3">
                <NavLink to='/' className="sidebar_links d-flex gap-3 justify-content-start align-items-center p-2 ps-4">
                    <img src={require('../assets/images/grid.svg').default} className="img-fluid sidebar_linkImg" />
                    <p className="sidebar_link_hint">Dashboard</p>
                </NavLink>
            </li>

            <li className="rounded-end-5 mb-3">
                <NavLink to='/exchange' className="sidebar_links d-flex gap-3 justify-content-start align-items-center p-2 ps-4">
                    <img src={require('../assets/images/exchange.svg').default} className="img-fluid sidebar_linkImg" />
                    <p className="sidebar_link_hint">Transaction</p>
                </NavLink>
            </li>

            <li className="rounded-end-5 mb-3">
                <NavLink to='/network' className="sidebar_links d-flex gap-3 justify-content-start align-items-center p-2 ps-4">
                    <img src={require('../assets/images/network.svg').default} className="img-fluid sidebar_linkImg" />
                    <p className="sidebar_link_hint">Bitcoin Network</p>
                </NavLink>
            </li>

            <li className="rounded-end-5 mb-3">
                <NavLink to='/userWallet' className="sidebar_links d-flex gap-3 justify-content-start align-items-center p-2 ps-4">
                    <img src={require('../assets/images/wallet.svg').default} className="img-fluid sidebar_linkImg" />
                    <p className="sidebar_link_hint">Users Wallet</p>
                </NavLink>
            </li>

            <li className="rounded-end-5 mb-3">
                <NavLink to='/supportTicket' className="sidebar_links d-flex gap-3 justify-content-start align-items-center p-2 ps-4">
                    <img src={require('../assets/images/ticket.svg').default} className="img-fluid sidebar_linkImg" />
                    <p className="sidebar_link_hint">Support Ticket</p>
                </NavLink>
            </li>

            <li className="rounded-end-5 mb-3">
                <NavLink to='/settings' className="sidebar_links d-flex gap-3 justify-content-start align-items-center p-2 ps-4">
                    <img src={require('../assets/images/setting.svg').default} className="img-fluid sidebar_linkImg" />
                    <p className="sidebar_link_hint">Settings</p>
                </NavLink>
            </li>
            <li className="rounded-end-5 mb-3">
                <NavLink to='/subscription' className="sidebar_links d-flex gap-3 justify-content-start align-items-center p-2 ps-4">
                    <img src={require('../assets/images/prime.svg').default} className="img-fluid sidebar_linkImg" />
                    <p className="sidebar_link_hint">Subscription</p>
                </NavLink>
            </li>
            <li className="rounded-end-5" onClick={handleShowLogout}>
                <NavLink className="sidebar_links d-flex gap-3 justify-content-start align-items-center p-2 ps-4">
                <img src={require('../assets/images/logout.svg').default} className="img-fluid sidebar_linkImg" />
                    <p className="sidebar_link_hint">Logout</p>
                </NavLink>
            </li>
            
        </ul>

        <div className='header_switch_holder d-md-none ps-4'>
                    <div className='header_dragger p-2 rounded-5 position-relative d-flex justify-content-center align-items-center w-100' >
                        <img src={switcher ? require('../assets/images/bitcoin.svg').default : require('../assets/images/thunder.svg').default} className={`img-fluid header_switchThunder ${switcher ? 'header_thunderRight' : 'header_thunderLeft'}`} onClick={()=> {setSwitcher(!switcher);handleClose()}} />
                        <p className={`header_switchHint ${switcher ? 'me-3' : 'ms-3'} `}>{switcher ? 'Switch to BTC' : 'Switch to LN'}</p>
                    </div>
                    </div>

        
        </Offcanvas.Body>
      </Offcanvas>
    {/* end of offcanva content */}

    {/* modals */}
            <LogoutModal show={showLogout} handleClose={handleCloseLogout} />
    {/* end of modals */}
    </>
  )
}

export default Header