import { combineReducers } from "redux";

const initialState = {
    switcher : false,
}

function reducer( state = initialState,action){
    switch(action.type){
        case 'switching'  :{
            return {
                ...state,...action.value
            }
        }
        default :{
            return initialState
        }
    }
}

export const kevlar = combineReducers({reducer : reducer})

