import logo from './logo.svg';
import './App.css';
import { Route, Routes } from 'react-router-dom';
import Dashboard from './Screens/Dashboard';
import '../src/assets/css/Style.css'
import UserWallet from './Screens/UserWallet';
import Network from './Screens/Network';
import ResetPassword from './Screens/ResetPassword';
import Exchange from './Screens/Exchange';
import SupportTicket from './Screens/SupportTicket';
import Profile from './Screens/Profile';
import Settings from './Screens/Settings';
import Subscription from './Screens/Subscription';
import AddSettings from './Screens/AddSettings';
import AddNetwork from './Screens/AddNetwork';
import AddSubscription from './Screens/AddSubscription';

function App() {
  return (
   <>
   <Routes>
    <Route path='/' element={<Dashboard/>} />
    <Route path='/userWallet' element={<UserWallet/>} />
    <Route path='/supportTicket' element={<SupportTicket/>} />
    <Route path='/network' element={<Network/>} />
    <Route path='/exchange' element={<Exchange/>} />
    <Route path='/profile' element={<Profile/>} />
    <Route path='/settings' element={<Settings/>} />
    <Route path='/subscription' element={<Subscription/>} />
    <Route path='/resetPassord' element={<ResetPassword/>} />
    <Route path='/addSettings' element={<AddSettings/>} />
    <Route path='/addNetwork' element={<AddNetwork/>} />
    <Route path='/addSubscription' element={<AddSubscription/>} />
   </Routes>
   </>
  );
}

export default App;
